import './App.css';

import React,{ useEffect } from 'react';

import { useMatomo } from 'matomo-react'

const App = () => {
  const { trackPageView } = useMatomo()
  useEffect(() => {
    trackPageView()
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <p>
          AdVoxel is updating. We should be back soon. {process.env.REACT_APP_VERSION}
        </p>
        <p></p>
      </header>
    </div>
  );
}

export default App;
